import React from 'react'
import { programLinks } from 'constant/links'

import SubLinks from 'components/Shared/SubLinks'

const JoinLinks = ({ location }) => (
  <SubLinks links={programLinks} location={location} />
)

export default JoinLinks
