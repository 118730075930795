import React from 'react'
import { Link } from 'gatsby'

import { Image } from 'components/Shared/Image'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.li`
  --height: clamp(20rem, 35vw, 45rem);
  display: block;
  width: 100%;
  height: var(--height);
  border: 0.2rem solid var(--color-white);
  cursor: pointer;
  position: relative;

  .lilliput {
    background-image: var(--gradient-lilliput);
  }
  .rainbow {
    background-image: var(--gradient-rainbow);
  }
  .green {
    background-image: var(--gradient-green);
  }
  .blue {
    background-image: var(--gradient-blue);
  }
  .purple {
    background-image: var(--gradient-purple);
  }
  .concert {
    background-image: var(--gradient-concert);
  }
  .youth {
    background-image: var(--gradient-youth);
  }
`
const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--color-white);
  opacity: 0.9;

  a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: calc(var(--font-size-default) * 0.7);
  }
`
const Info = styled.div`
  --margin: clamp(1.25rem, 12.5vw, 2rem);
  width: 95%;
  position: absolute;
  bottom: var(--margin);
  right: var(--margin);

  h2,
  p {
    --text-align: right;
    --margin-top: 0;
    --margin-bottom: 0;
    --line-height: 2;
    opacity: 0.9;
    &:last-child {
      --margin-bottom: 0;
    }
  }

  span {
    --span: clamp(10rem, 10vw, 12rem);
    display: block;
    position: absolute;
    left: var(--margin);
    bottom: var(--margin);
    font-size: var(--span);
    line-height: var(--span);

    svg {
      --size: clamp(7rem, 8vw, 10rem);
      width: var(--size);
      height: var(--size);
    }
  }
`
//#endregion

const Program = ({ program }) => {
  const { level, title, grade, desc, css } = program.frontmatter
  return (
    <Wrapper className={`program ${css}`}>
      <Image image={program.image} alt={title} />
      <Content className={css}>
        <Link to={`/programs/${title}`}>
          <Info>
          <h2>
            {!level ? (
              <span>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                  <path d='M480 0h-64c-40.7 0-77.5 15.7-105.8 40.8 25.4 32 40.9 72.2 41.6 116C424.9 142 480 77.5 480 0zM256 160v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V160C320 71.6 248.4 0 160 0H96c0 88.4 71.6 160 160 160zm309.3 168.1c-11.8-10.7-30.2-10-42.6 0L430.3 402c-11.3 9.1-25.4 14-40 14H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h78.3c15.9 0 30.7-10.9 33.3-26.6 3.3-20-12.1-37.4-31.6-37.4H192c-27 0-53.1 9.3-74.1 26.3L71.4 384H16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h356.8c14.5 0 28.6-4.9 40-14L564 377c15.2-12.1 16.4-35.3 1.3-48.9z' />
                </svg>
              </span>
            ) : (
              <span>{level}</span>
            )}
            {title}
          </h2>
          <p>{grade}</p>
          <p>{desc}</p>
          </Info>
        </Link>
      </Content>
    </Wrapper>
  )
}

export default Program
