import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import ProgramNav from 'components/Public/ProgramNav'
import Program from 'components/Public/Program'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.section`
  ${p => p.theme.fullbleed}
  width: 100%;

  .programs {
    --grid-column: 1;
    --grid-template-columns: repeat(2, 1fr);
    ${p => p.theme.mq.sm`
      --grid-template-columns: repeat(3, 1fr);
    `}
    ${p => p.theme.grid}

    .program:nth-child(2n + 1) {
      --grid-column: 1;
    }
    .program:nth-child(2n) {
      --grid-column: 2;
    }

    @media only screen and (min-width: 768px) {
      .program:nth-child(3n + 1) {
        --grid-column: 1;
      }
      .program:nth-child(3n + 2) {
        --grid-column: 2;
      }
      .program:nth-child(3n) {
        --grid-column: 3;
      }
    }
  }
`
//#endregion


const Programs = ({ data, location }) => {
  const {
    programs: { nodes: programs },
    imageFiles: { nodes: images},
    seo,
  } = data
  const { title, description } = seo.frontmatter.programs

  const programsInfo = programs.map( program => {
    const img = images.filter( image => image.name === program.frontmatter.css)
    return ({...program, image: { 'childImageSharp': img[0].childImageSharp}})
  })

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <ProgramNav location={location} />
      <Wrapper>
        <Title>晶晶課程</Title>
        <ol className='programs'>
          {programsInfo.map(program => (
            <Program program={program} key={program.frontmatter.title} />
          ))}
        </ol>
      </Wrapper>
    </PageContent>
  )
}

export default Programs

export const query = graphql`
  {
    programs: allMdx(
      filter: { frontmatter: { type: { eq: "program" } } }
      sort: { fields: frontmatter___level }
    ) {
      nodes {
        frontmatter {
          level
          title
          grade
          desc
          css
        }
      }
    }

    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        programs {
          title
          description
        }
      }
    }

    imageFiles: allFile(filter: { relativeDirectory: { eq: "program" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`
